div.col-sidebar {
  div.ccm-block-date-navigation-wrapper {
    font-size: .85em;
  }
}

ul.ccm-block-date-navigation-dates {
  list-style-type: none;
  padding-left: 0px;
  padding-bottom: 0px;

  li {
    padding: 10px;
    background-color: $brand-primary;
    margin-bottom: 10px;
    &:hover{
      background-color: $brand-primary;
    }
    i{
      color: white;
    }
    a{
      margin-left: 5px;
      color: white!important;
    }
  }
}

.ccm-block-date-navigation-date-selected {
  font-weight: bold;
}

