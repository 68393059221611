.link-block{
  width: 100%;
  display: inline-block;
  .list-group-header{
    min-height: 200px;
    background-size: cover;
  }
  h2{
    color: #fff;
  }
}