.gallery-wrapper{
  width: 100%;
  margin: 0 0 25px 0;
  padding: 2px 2px 0 0;
  .gallery-item{
    float: left;
    background-color: transparent;
    border: none;
    width: auto;
    height: 160px;
    width: 25%;
    overflow: hidden;
    //height: 160px;
    padding: 15px;
    &:hover{
      img{
        opacity:.8;
      }
    }
    img{
      width: 100%;
      height: 160px;
      //width:auto;
      //height:100%;
      @include transition(all .2s ease);
    }
  }
}