div.recent-blog-entry {
  div.ccm-block-page-list-title {
    margin-top: 0px;
    margin-bottom: ($line-height-computed / 2);
    @include make-heading($font-family-base, $font-size-h2, 700, $brand-primary);

    a {
      color: $brand-primary;
      &:hover {
        color: darken($brand-primary,20%);
        text-decoration: none;
      }
    }
  }

  div.ccm-block-page-list-page-entry {
    border-bottom: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

}