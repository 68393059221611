.accordion-toggle{
  display: block;
}
.accordion-toggle .fa {
  font-family: FontAwesome;
  content: "f078";
  float: right;
  font-size: 14px;
  line-height: 22px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.collapsed .fa {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
.panel{
  border-radius: 0;
}