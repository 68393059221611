.ccm-block-next-previous-wrapper{
  width: 100%;
  height: 40px;
  .ccm-block-next-previous-previous-link,.ccm-block-next-previous-next-link{
    float: left;
    padding: 15px;
    background: $brand-primary;
    margin-right:10px;
    width: 100%;
    font-size: 20px;
    a{
      color:white!important;
    }
  }
  .ccm-block-next-previous-parent-link{
    float: left;
    padding: 5px 15px;
    background: $brand-primary;
    a{
      color:white!important;
    }
  }
  .ccm-block-next-previous-header{
    display: none;
  }
}