.ccm-block-feature-item{
  h4{
    text-align: center;
    margin-top: 5px;
    .fa{
      display: block;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      color: $brand-primary;
      background-color: white;
      border: 2px solid $brand-primary;
      text-align: center;
      line-height: 70px;
      font-size: 30px;
      margin: 0 auto;
      @include transition(all .4s linear .0s);
      &:hover{
        background-color: $brand-primary;
        color: white;
        -ms-transform: scale(1.3);
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
      }
    }
    a{
      display: block;
      text-align: center;
      text-decoration: none;
      font-size: 25px;
      color: $black;
      line-height: 70px;
    }
  }
  p{
    text-align: center;
  }
}