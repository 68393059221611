#mc-form-wrapper{
  >div{
    >form{
      min-height:30px;
      height:auto;
      margin:6px 0;
      .ccm-input-submit{
        height:30px;
        padding-top:5px;
        padding-bottom:5px;
        margin-top:5px;
        width: 250px;
        color:white;
        line-height: 20px;
        background:$brand-primary;
        &:hover{
          background:$gray;
        }
      }
      >.form-group{
        width:auto!important;
        float:left;
        height:auto;
        margin:5px 10px 5px 0;
        >input{
          width:250px;
          text-indent: 5px;
          margin:0;
          height:30px;
          box-shadow:none;
          -webkit-box-shadow:none;
          border-radius:0px;
          &:hover,&:active{
            border-color: $brand-primary;
          }
        }
      }
    }
  }
}
