.pika-stage{
  > img{
    width:100%;
    height:auto;
  }
}
.pika-imgnav,.pika-aniwrap,.pika-textnav{
  display:none!important;
}
.pika-thumbs{
  margin-top:10px;
  width:100%;
  height:auto;
  li{
    width:150px;
    margin-right:10px;
    margin-bottom:10px;
    display:inline-block!important;
    float:left!important;
    position:relative!important;
    opacity: 1!important;
  }
  li.active{img{height:112px;}border:4px solid black;}
  img{
    height:120px;
    width:auto;
  }
}

.rslides_tabs{
  list-style-type:none;
  display: table-cell!important;
  li{
    display:inline-block;
    float:left;
    margin-right:5px;
    border-radius:50%;
    border: 2px solid black;
    width:15px;
    height:15px;
    a{
      width:15px;
      height:15px;
      display: block;
      color:transparent;
    }
  }
  li.rslides_here{
    border-radius:50%;
    border: 2px solid black;
    background-color: black;
    a{
      content:'/'!important;
    }
  }
}
.rslides_thumbs{
  list-style-type: none;
  padding-left: 0;
  display: table-header-group!important;
  top:10px;
  .slider-thumb{
    float:left;
    margin-right:10px;
    a{
      height:100px;
      width:150px;
      display:block;
      background-size:cover!important;
    }
  }
}
.thumb-slider{
  .ccm-image-slider-text{
    position:absolute;
    margin-top:20%;
    text-align:center;
    width:100%;
    div{
      display:block;
      text-align: center;
      height:auto;
      position:relative;
      min-height:40px;
      margin:10px 0;
    }
    h2{
      background:rgba(255,255,255,.6);
      color:black;
      padding:10px 25px;
      text-align:center;
      font-size:15px;
      width:auto;
      display: inline;
      position:relative;
      height:auto;
    }
    p{
      background:rgba(0,0,0,.6);
      color:white;
      padding:10px 25px;
      font-size:15px;
      width:auto;
      display: inline;
      position:relative;
      height:auto;
    }
  }
}
ul.rslides {
  margin-bottom:10px;
  .ccm-image-slider-text{
    position: absolute;
    width: 40%;
    left: 7%;
    top: 20%;
    h2{
      font-weight: 600;
      color: white;
      text-shadow: 0 1px 5px #707070;
    }
  }
}

// LOGO SLIDER //

.logo-slider,.outer-scroller,.inner-scroller{
  position:relative;
  width:100%;
  height:100%;
  overflow-x:hidden;
}
.images-slider{
  display:block;
  height:100px;
  float:left;
  position:relative;
  >li{
    position:relative;
    float:left;
    display:inline-block;
    width:120px;
    margin-right:40px;
    height:100%;
    >a{
      position:relative;
      display:block;
      width:100%;
      height:100%;
      background-size:contain!important;
      background-repeat:no-repeat;
    }
  }
}


.logo-slider-header{
  color:black;
  opacity:0.6;
  font-weight:400;
  text-align:center;
  font-size:16px;
}
.logo-slider{
  padding-top:0px;
  width:100%;
  height:80px;
  float: none;
  position:relative;
  margin-bottom:10px;
  .outer-scroller{
    width:100%;
    height:80px;
    overflow:hidden;
    .inner-scroller{
      width:auto;
      height:100%;
      overflow:hidden;
      ul{
        list-style:none;
        height:100%;
        width:auto;
        //.transition(move 1s linear);
        li{
          width:130px;
          height:100%;
          margin:0 20px;
          cursor: pointer;
          position:relative;
          float:left;
          .slider-img{
            width:100%;
            height:100%;
            display:block;
            text-decoration:none;
            background-repeat:no-repeat;
            background-position:center center;
            background-size:contain!important;
            filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
            opacity:0.9;
           // .transition(all .5s linear);
            &:hover {
              opacity:1;
            }
          }
        }
      }
    }
  }
}

//