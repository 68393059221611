div.blog-entry-list {

  div.ccm-block-page-list-page-entry-horizontal {
    @include make-row();

    div.ccm-block-page-list-page-entry-thumbnail {
      @include make-xs-column(2);
      padding-top: 7px; // to counteract the h3 line height

      img {
        @include img-responsive();
      }
    }
    div.ccm-block-page-list-page-entry-text {
      @include make-xs-column(10);
    }
  }

  div.ccm-block-page-list-date {
    color: lighten($black, 25%);
    font-size: 0.8em;
    margin-bottom: 10px;
  }

  div.ccm-block-page-list-title {
    margin: 0px;
    @include make-heading($font-family-base, $font-size-h2, 700, $brand-primary);

    a {
      color: $brand-primary;
      &:hover {
        text-decoration: none;
        color: darken($brand-primary,20%);
      }
    }

  }
}