div.block-sidebar-wrapped {
  padding: 25px 40px 25px 40px;
  background-color: $brand-primary;
  font-size: 0.85em;

  h3 {
    margin: 0px;
    font-size: 1em;
    font-weight: normal;
  }

  a {
    color: $white !important;
    &:hover {
      text-decoration: none;
      color: $white !important;
    }
  }

  div.ccm-block-page-list-page-entry {
    padding-bottom: 0px;
    border-bottom: 0px;
    margin-bottom: 0px;
  }

  ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    color: $white !important;
    li {
      margin-bottom: 12px;
    }

    ul {
      padding-left: 20px;
      font-size: 1em;
    }
  }

}

div.block-sidebar-wrapped + div.block-sidebar-wrapped {
  padding-top: 25px;
}

// blocks in blog entry
div.block-sidebar-wrapped {
  div.ccm-block-share-this-page {
    margin: -15px -40px -25px -40px;
    padding: 15px 40px 10px 40px;
    border-top: 1px solid $white !important;;
    font-size: $font-size-h3;

    a {
      color: $white !important;
      &:hover {
        color: $white !important;
      }
    }
  }
}