#menucard-frontend-block{
  h1{
    margin: 20px 0 0 0;
  }
  li.category-nav-item a{
    color: white;
    background-color: $brand-primary;
    &:hover,&[aria-expanded="true"]{
      background-color: $dark-gray;
    }
  }
  .tab-content{
    border: 0;
  }
}
.category-title span{
  background-color: transparent;
  font-size: 24px;
  color: black;
}
.menucard-category{
  margin-bottom: 20px;
}
.menucard-dish{
  border-bottom: 1px dashed $brand-primary;
  h4{
    font-size: 18px;
    font-weight: 400;
  }
  .menucard-dish-description{
    font-weight: 200;
  }
  .menucard-dish-price, .menucard-dish-price span{
    font-weight: 400!important;
  }
}
#categoryName{
  font-weight: 400;
  width: auto;
  padding: 10px 15px;
  text-align: center;
  color: white;
  font-size: 20px;
  background-color: $brand-primary;
  position: relative;
  margin: 0 auto;
  float: none;
  display: table;
}
.category-nav {
  margin-bottom: 20px;
  background-color: transparent!important;
  li a[aria-expanded="true"]{
    background-color: black;
  }
  li a{
    //.transition(background-color .5s ease);
    background-color: $brand-primary;
    &:hover{
      color: white;
    }
  }
}