$white: #ffffff;
$black: #1a1a1a;
$gray: #dadada;
$dark-gray: #404040;
$light-gray: #dadada;
$blue: #294f6d;
$green: #114610;
$red: #940000;
$orange: #f6a636;
$kiwi-green: #61b442;
$pink: #fabfdb;
$turq: #38d8c0;

$brand-primary: $green;
$brand-secondary: $kiwi-green;
$brand-info: $blue;
$brand-success: $green;
$brand-danger: $red;
$brand-warning: $orange;

$fa-font-path: '/packages/kiwi_themes/themes/venetie/fonts/';
//$font-family-sans-serif:  'Josefin Sans', sans-serif;
$font-family-sans-serif:  'Montserrat', sans-serif;

$font-size-base: 15px;

$headings-font-weight: 400;
$headings-color: $black;

$body-bg:$white;
$text-color: $black;

$border-radius-base:5px;

//INPUTS
$input-bg: transparent;
$input-border:$brand-primary;