//--- APPLICATION
@import "blaris/variables";
@import "blaris/mixins";

//--- VENDOR
@import "bootstrap/bootstrap";
@import "font-awesome/font-awesome";

//--- ELEMENTS
@import "blaris/elements/body";

div.ccm-page {
  //--- ELEMENTS
  @import "blaris/elements/header";
  @import "blaris/elements/footer";
  //--- C5 BLOCKS
  @import "blaris/blocks/autonav";
  @import "blaris/blocks/content";
  @import "blaris/blocks/custom-image-slider";
  @import "blaris/blocks/date-navigation";
  @import "blaris/blocks/faq";
  @import "blaris/blocks/feature";
  @import "blaris/blocks/form";
  @import "blaris/blocks/image";
  @import "blaris/blocks/image_slider";
  @import "blaris/blocks/next-previous";
  @import "blaris/blocks/page-list";
  @import "blaris/blocks/page-title";
  @import "blaris/blocks/social-links";
  @import "blaris/blocks/kiwi-button";
  @import "blaris/blocks/link-block";
  @import "blaris/blocks/mailchimp";
  @import "blaris/blocks/menucard";
  @import "blaris/blocks/vivid-gallery";
  @import "blaris/blocks/youtube-video";
  @import "blaris/blocks/customvideo";
  @import "blaris/blocks/testimonials";
  @import "blaris/blocks/video-image-slider";

  //C5 THEME BLOCK CLASSES
  @import "blaris/theme-block-classes/blog-entry-list";
  @import "blaris/theme-block-classes/block-sidebar-wrapped";
  @import "blaris/theme-block-classes/block-sidebar-padded";
  @import "blaris/theme-block-classes/recent-blog-entry";

  //PAGES
  @import "blaris/pages/home";
  @import "blaris/pages/maps";
  @import "blaris/pages/news-item-page";
}

//--- ELEMENTS
@import "blaris/elements/mobile-nav";