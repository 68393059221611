p{
  >img,>a>img{
    max-width: 100%;
    height:auto!important;
  }
}
@media all and (max-width: $screen-xs-max) {
  p{
    >img,>a>img{
      height:auto!important;
    }
  }
}
h1,h2,h3,h4,h4,h6{
  font-weight: 200;
}
h1{
  margin-top: 0;
}