.main-nav{
  padding-right:0;
  #top-nav{
    padding-right:0;
    ul{
      margin-bottom: 0;
      margin-right: 0;
      float: right;
      .nav-selected, .nav-path-selected{
        > a{
          color:$brand-primary;
        }
      }
      li{
        display: inline-block;
        margin-right: 20px;
        line-height: 25px;
        &:last-child{
          margin-right:0;
        }
        a{
          font-size:17px;
          color:$black;
          font-weight:600;
          text-transform: uppercase;
          &:hover{
            text-decoration: none;
            color:$brand-secondary;
          }
        }
      }
    }
  }
}
nav[aria-label=breadcrumb]{
  margin-bottom: 10px;
  .breadcrumb{
    padding-bottom: 25px;
    padding-top:0;
    .active{
      color: $brand-primary;
      font-weight:600;
    }
  }
}
ul.pagemenu.nav{
  li{
    background-color: #eee;
    a{
      color: $brand-primary;
      &:hover{
        background-color: $brand-primary;
      }
    }
    &:hover, &.nav-selected, &.nav-path-selected{
      background-color: $brand-primary;
      a{
        color: #eee;
      }
    }
  }
}
footer{
  ul.nav{
    li{
      a{
        padding: 5px;
        background-color: transparent;
      }
    }
  }
}