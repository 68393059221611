// -----------------------------------------------------------------------------
// Video Slider - Default
// -----------------------------------------------------------------------------
.video-swiper-container{
  padding: 0 60px;
  .swiper-wrapper{
    .swiper-slide{
      i{
        position: absolute;
        top:0;
        left:120px;
        width: 30px;
        height:20px;
        background-color: $white;
        text-align: center;
      }
    }
  }
  .video-swiper-button-next, .video-swiper-button-prev {
    position: absolute;
    top: 0;
    width: 60px;
    height: 100%;
    z-index: 10;
    cursor: pointer;
    background-color: $white;
    line-height: 100px;
    i{
      color: $brand-primary;
    }
  }
  .video-swiper-button-prev {
    text-align: left;
    left:0;
  }
  .video-swiper-button-next {
    text-align: right;
    right:0;
  }
}

// -----------------------------------------------------------------------------
// Video Slider - Fullscreen Swiper Slider
// -----------------------------------------------------------------------------
.fullscreen-video-image-slider-swiper-container{
  margin:0 -15px;
  padding-bottom:30px;
  .swiper-slide{
    text-align: center;
    padding:0;
    height:800px;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    img{
      max-width: 100%;
      width:auto;
      height:auto;
      max-height: 580px;
    }
    .title{
      margin:30px 0 10px 0;
      color:$white;
      h2{
        color:$white;
        font-weight: 900;
        font-size: 4em;
      }
    }
  }
  .video-swiper-button-next,.video-swiper-button-prev{
    position: absolute;
    font-family: FontAwesome;
    font-size:3em;
    padding:15px;
    line-height: 120px;
    text-align: center;
    color:$white;
    background-image:none!important;
    height:150px;
    width: 70px;
    margin-top:-75px;
    top:50%;
    background-color: $brand-primary;
  }
  .video-swiper-button-next{
    right:0;
    @include fa(caret-right);
  }
  .video-swiper-button-prev{
    left:0;
    @include fa(caret-left);
  }
  .swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    background-color: $brand-primary;
    &.swiper-pagination-bullet-active{
      background-color: $brand-primary;
      width: 20px;
      height: 20px;
      margin-bottom:-3px;
    }
  }
  .video-swiper-scroll-down{
    position: absolute;
    left: 50%;
    margin-left:-30px;
    bottom:30px;
    border-radius: 50%;
    border:3px solid $brand-primary;
    width:60px;
    height:60px;
    padding:10px;
    cursor: pointer;
    @include transition(all 0.3s linear);
    &:before{
      margin-left: 2px;
      text-align: center;
      font-size: 3em;
      line-height: 40px;
      color:$brand-primary;
      font-family: FontAwesome;
      @include fa(angle-down);
    }
    &:hover{
      @include scale(1.3);
    }
  }
}

@media all and (max-width: $screen-sm){
  .fullscreen-video-image-slider-swiper-container{
    .video-swiper-button-next,.video-swiper-button-prev{
      font-size:2em!important;
      padding:5px!important;
      line-height: 30px!important;
      height:40px!important;
      width: 40px!important;
      margin-top:-20px!important;
    }
    .title{
      h2{
        font-size: 2em!important;
      }
    }
  }
}