.btn{
  //box-shadow: 0 0 6px 0 #a6a6a6;
  box-shadow: none;
  border-radius: 0;
  margin-bottom: 15px;
  color: white;
  line-height: 30px;
  padding: 5px 10px;
  @include transition(all 1s ease);
  &:hover{
    background-color: $brand-primary!important;
  }
}
.btn-default{
   background-color: $brand-secondary!important;
 }
.btn-warning{
  background-color: $orange!important;
}
.btn-danger{
  background-color: $red!important;
}
.btn-success{
  background-color: $green!important;
}
.btn-info{
  background-color: $black!important;
  &:hover{
    background-color: $brand-primary!important;
  }
}
.big-btn{
  width: 100%;
  padding: 20px;
  font-size: 2rem;
}
.btn-go-to{
  i{
    margin-left: 5px;
    font-size: 11px;
  }
}