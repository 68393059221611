.mvp-auto-player{
  margin-top:20px;
  margin-bottom:20px;
  float:left;
  display:block;
  position:relative;
  min-height:150px;
  //background-image:url('/packages/kiwi_themes/themes/venetie/images/newest-ipad.png');
 // background-repeat:no-repeat;
 // background-position:top left;
 //background-size:100% 100%;
  //padding:11.6% 9.6% 11.5% 11.6%!important;
  //padding:6% 9.1% 6% 9.3%!important;
  padding:38px 68px 38px 68px;
  &.no-skin{
    padding:0!important;
    background:none!important;
    margin:0!important;
  }
  .mejs-container,video,.mejs-layer{
    width:100%!important;
    height:100%!important;
  }
  .error{
    display:none!important;
  }
  .vjs-tech,.video-js{
    background-size:cover;
    background-repeat:no-repeat;
    background-position:top center;
  }
}
