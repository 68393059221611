.breadcrumbs{
  display:block;
  padding:0;
  .breadcrumb{
    border-radius: 0;
    margin-bottom: 0;
    background-color:transparent;
    li{
      font-size:13px;
    }
    a{
      color: $brand-primary;
      font-size:13px;
      &:hover{
        text-decoration: none;
      }
    }
  }
}
.wrapper{
  overflow-x: hidden;
  background-color: $white;
  z-index:10;
  &.pushable{
    @include transition-transform(.25s ease-in-out);
    &.pushed{
      position:fixed;
      @include translate(250px,0);
      header .header-logo{
        display:none;
      }
    }
  }
  @media all and (max-width: $screen-sm-max) {
    @include box-shadow(-4px 0px 6px -3px rgba(0,0,0,0.3));
    .youtube-footer {
      .col-sm-3 {
        margin-bottom: 15px;
        width: 46.5%;
        float: left;
      }
    }
  }
  @media all and (max-width: $screen-xs) {
    .youtube-footer   {
      .col-sm-3 {
        margin-right: 0;
        margin-bottom: 15px;
        width: 100%;
      }
    }
  }
  &.page-template-home{
    main .container{
      border: 0!important;
    }
  }
  main{
    margin-top: 15px;
    >.container:first-child{
      border-top: 1px solid #F0F0F0;
    }
    >.container:last-child{
      border-bottom: 1px solid #F0F0F0;
    }
    >.container{
      border-right: 1px solid #F0F0F0;
      border-left: 1px solid #F0F0F0;
    }
  }
}
.page-bottom-container{
  margin-top: 20px;
  margin-bottom: 20px;
  min-height:40px;
}
.none{
  display: none!important;
}
.block{
  display: block!important;
}
.scrollUp{
  position:fixed;
  width:40px!important;
  height:40px!important;
  right:10px;
  bottom:10px;
  z-index:300;
  display:block;
  font-size:24px;
  text-align: center;
  font-family: FontAwesome;
  cursor:pointer;
  background: $brand-secondary;
  color: $white;
  i{
    line-height: 40px;
    font-weight: 400;
  }
}
.fix{
  margin-top: 138px;
}
.youtube-footer{
  .col-sm-3{
    padding: 10px;
    border: 1px solid #F0F0F0;
    margin-right: 15px;
    width:23%;
    color: $brand-primary;
    &:last-child{
      margin: 0;
    }
  }
}