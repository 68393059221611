#slider1_container{
  >div{
    position: relative!important;
  }
  #slide-image{

  }
  #image-content{
    position: absolute;
    padding: 10px;
    background-color: $brand-primary;
    color: white;
    height:auto;
    width:100%;
    font-size: 20px;
    p{
      margin-bottom: 0;
    }
  }
}
.arrow{
  position: absolute;
  top: 40%;
  height: 50px;
  width: 30px;
  color: white;
  background-color: rgba(0,0,0,.4);
  z-index: 100;
  i{
    cursor: pointer;
    font-size: 25px;
    text-align: center;
    line-height: 50px;
    width: 100%;
  }
}
#left-arrow{
  left: 0;
}
#right-arrow{
  right: 0;
}
.btn{
  background-color: rgba(255,255,255,.3);
  @include boxshadow(0px, 0, 10px, 0, #fff);
  margin-top: 10px;
  border: none;
  &:hover{
  //border-color: darken($brand-primary,20%);
  }
}
.contentonimg{
  #image-content{
    position: absolute;
    width: 100%;
    height: 20%!important;
    color: white;
    top: 40%;
    font-size:30px!important;
    background-color: transparent!important;
    text-shadow: 1px 1px 1px black;
    text-align: center;
    text-transform: uppercase;
    .btn{
      text-shadow: none;
      padding: 6px 15px;
    }
    h2{
      color:white;
    }
    @media screen and (max-width:$screen-md-max){
      font-size: 20px;
    }
    @media screen and (max-width:$screen-sm-max){
      font-size: 16px;
    }
    @media screen and (max-width:700px){
      font-size: 14px;
    }
    @media screen and (max-width:550px){
      display:none;
    }
    line-height: 30px;
  }
}
@media all and (max-width: $screen-xs){
  #image-content{
    display: none;
  }
}