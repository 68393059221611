body.home{
  header{
    background:transparent;
    .header-title{
      display:none;
    }
  }
  .banner-area{
    width: 90%;
    margin: 20px 5%;
    height:350px;
    .ccm-image-slider-container,.ccm-image-slider,.ccm-image-slider-inner,.rslides,.rslides li{
      width: 100%;
      height: 100%;
    }
    .rslides li{
      background-position: top center;
      background-size: cover;
      background-repeat: no-repeat;
      .slide-text{
        width: 100%;
        position: absolute;
        bottom: 0;
        color: $white;
        >.container{
          background-color: rgba(43, 43, 42, 0.6);
          padding:20px;
        }
        h2{
          color: $white;
          &:hover{
            color:darken($white,20%);
          }
        }
      }
    }
  }
  @media all and (min-width: $screen-sm) {
    .wrapper{
      background:transparent!important;
      position: absolute;
      height: 100%;
      width: 100%;
    }
    footer{
      position: absolute;
      bottom: 34px;
      width: 100%;
      &.branding{
        bottom:0px;
      }
    }
    .banner-area,.ccm-image-slider-container,.ccm-image-slider,.ccm-image-slider-inner,.rslides,.rslides li{
      height: 100%;
      width: 100%;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 0;
      z-index: -1;
    }
    .banner-area{
      .slide-text{
        bottom:263px!important;
      }
    }
  }
}
.home-top-slider{
  margin-bottom: 20px;
  .container{
    padding:0;
  }
}
@media all and (max-width: $screen-xs-max) {
  .home .wrapper{
    position: absolute;
    width:100%;
    height:100%;
  }
}

@media all and (min-width: $screen-sm) {
  #mobileMainNav {
    display: none !important;
  }
}