header{
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  .top-container{
    position: relative;
    border-bottom: 1px solid #F0F0F0;
    padding: 0;
    .absolute-top{
      height:50px;
      border-bottom:1px solid #F0F0F0;
      padding: 0 10px;
      .top-social{
        padding: 0;
      }
      .top-language{
        padding-right: 0;
        h4{
          font-weight: 200;
          margin-top: 15px;
        }
        .ccm-block-switch-language{
          float: right;
          right: 0;
          margin-top: 5px;
          select{
            border-radius: 0;
            height: 30px;
          }
        }
      }
    }
  }
  .navbar-toggle{
    background-color: $brand-secondary;
    color:$white;
    padding:11px 13px;
    border:1px solid $brand-secondary;
    border-radius: 0px;
    margin:10px 10px 0 10px;
    line-height: 20px;
    @include transition(all .2s ease-in-out .1s);
    &:focus,&:active{
      border:1px solid $brand-secondary;
      background-color: $brand-secondary;
    }
    &:hover{
      background-color: lighten($brand-secondary, 20%);
    }
    &.active{
      float:left;
      margin-bottom:36px;
    }
  }
  .header-logo{
    float: left;
    margin:20px 0;
    height:auto;
    a, a img{
      width:100%;
      height:auto;
    }
  }
  .main-nav{
    border: none;
    margin-top:30px;
    margin-bottom:0;
    position: relative;
    right: 0;
    min-height: 10px;
    #top-nav{
      margin-top: 5px;
      padding-left: 0;
      ul.navbar-nav{
        li{
          position: relative;
          &.has-children{
            .menu-group-indicator{
              display: none;
            }
          }
          a{
            padding:0;
            font-size: 1em;
            font-weight: 400;
            text-transform: uppercase;
            background: transparent;
            @include transition(all .2s ease-in-out .1s);
            span{
            }
          }
          &:hover{
            >.sub-nav{
              display: block;
            }
          }
          .sub-nav{
            position: absolute;
            top:21px;
            min-width:190px;
            right:auto;
            display:none;
            list-style: none;
            padding: 20px 0 0 0;
            z-index: 100;
            li{
              background-color: white;
              @include boxshadow(0px, 3px, 3px, 0px, #8c8c8c);
              &:hover{
                >a{
                  text-decoration:none;
                  color: $brand-secondary;
                }
                .sub-nav{
                  display: block;
                }
              }
              //border-bottom: 1px solid white;
              float: left;
              display:block;
              width:100%;
              a.nav-selected{
                color: $brand-primary;
              }
              a{
                font-size: 0.8em;
                float:left;
                display:block;
                width:100%;
                padding:0px 10px;
                color: $black;
                span{
                  display:inline-block;
                  margin-left:0px;
                  margin-top:0px;
                }
              }
              .sub-nav{
                display:none;
                left: 100%;
                top: 0;
                padding-top: 0;
                li{
                  box-shadow: 0 1px 1px 0 #8c8c8c!important;
                }
              }
            }
          }
        }
      }
    }
  }
}
header.fixed{
  position: fixed;
  z-index: 500;
  background-color: white!important;
  width: 100%;
  margin-top: -1px;
  .absolute-top{
    display: none;
  }
}