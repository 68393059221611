a.ccm-block-page-list-rss-feed{
  font-size: 1.5em;
  color: $brand-primary;

  &:hover {
    color: darken($brand-primary,20%);
  }
}

.ccm-pagination-wrapper{
  display: inline-block;
  width: 100%;
}

div.ccm-block-page-list-page-entry-horizontal {
  @include clearfix();
}

div.ccm-block-page-list-page-entry-horizontal,
div.ccm-block-page-list-page-entry {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid $brand-primary;
  .ccm-block-page-list-page-entry-thumbnail{
    img{
      border:1px solid $brand-primary;
      width: 150px;
    }
  }
  .ccm-block-page-list-date{
    font-size:0.8em;
    font-weight:700;
  }
}

div.ccm-block-page-list-page-entry-read-more {
  margin-top: 20px;
}

div.ccm-block-page-list-no-pages {
  text-align: center;
  font-size: $font-size-h3;
  color: $brand-primary;
}

div.col-sidebar {
  div.block-sidebar-wrapped {
    div.ccm-block-page-list-wrapper {
      margin: 0px;
    }
  }
}

div.col-sidebar {
  div.ccm-block-page-list-wrapper {
    margin: 25px 40px 25px 40px;
    font-size: .85em;

    a.ccm-block-page-list-rss-feed {
      font-size: .85em;
    }
    div.ccm-block-page-list-page-entry {
      border: 0px;
      padding: 0px;
      margin-bottom: ($line-height-computed / 2);
      a {
        font-weight: normal;
      }
    }
  }
}

div.ccm-block-page-list-header {
  font-size: 1em;
}
.ccm-block-page-list-page-entry-grid-item{
  width: 47%;
  float: left;
  margin-bottom: 20px;
  margin-right: 10px;
  overflow:hidden;
  &:nth-child(even){
    margin-right: 0;
  }
  &:hover{
    .ccm-block-page-list-page-entry-grid-thumbnail-title{
      bottom:0;
    }
  }
  .ccm-block-page-list-page-entry-grid-thumbnail-title{
    font-size: 21px;
    font-weight: 600;
    padding: 10px 0;
    background-color: $brand-primary;
    color: $white;
    height: auto;
    bottom: -60px;
    position: absolute;
    display: inline-block;
    @include transition(all .25s ease-in-out .1s);
  }
}

// Home Thumbs //


.ccm-block-page-list-thumbnail-grid-wrapper{
  width: auto;
  display: inline;
  .pagelist-banner-wrapper:first-child{
    width:50%;
    height:402px;
  }
  .pagelist-banner-wrapper{
    width: 24.6%;
    float: left;
    margin-left: 2px;
  }
  @media all and (max-width: $screen-md){
    .pagelist-banner-wrapper{
      width:100%;
      &:first-child{
        width:100%;
      }
    }
  }
}
.pagelist-banner-wrapper{
  position:relative;
  width:100%;
  height:200px;
  margin-bottom: 2px;
  .pagelist-banner-image{
    z-index:110;
    background-size: cover;
    position:absolute;
    width:100%;
    height:100%;
    >h4{
      position:absolute;
      bottom:0;
      background: rgba(0, 0, 0, 0.5);
      padding: 10px;
      margin-bottom: 0;
      width: 100%;
      color: white;
    }
  }
}

//