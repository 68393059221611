#mobileMainNav{
  width:250px;
  background-color: #fafafa;
  position: fixed;
  height: 1000px;
  overflow-y: auto;
  top:0;
  left:0;
  visibility: hidden;
  z-index:0;
  @include transition(visibility .0s linear .1s);
  &.active{
    height:100%;
    visibility: visible;
  }
  .header-logo{
    background-color: #fafafa;
    margin-top: 20px;
    color: lighten(grey, 20%);
    a {
      color:  $white;
      display: block;
      height: 82px;
      padding: 10px;
      img{
        width: auto;
        height: 100%;
      }
    }
  }
  .nav-mobile{
    .nav{
      width: 100%;
      margin:0;
      padding:0;
      background-color: #e2e2e2;
      li{
        margin-top:1px;
        float:left;
        width:100%;
        display:block;
        &:first-child{
          margin-top: 0;
        }
        &:last-child{
          margin-bottom:1px;
        }
        &.has-children{
          .menu-group-indicator{
            display: block;
            position: absolute;
            padding:5px 10px;
            right:20px;
            top: 8px;
            color: $brand-secondary;
            border: 1px solid $brand-secondary;
            background-color: transparent;
            &:active,&:focus{
              outline: none;
            }
          }
        }
        &:hover{
          >a{
            span{color: $brand-secondary;}
            width:100%;
          }
        }
        &.active,&.nav-path-selected{
          .menu-group-indicator{
            color: $white;
            border: 1px solid $white;
          }
        }
        a{
          padding:12px 20px;
          width:100%;
          float:left;
          display:block;
          background-color: #f0f0f0;
          span{
            padding-top:3px;
            position: relative;
            display: inline-block;
            width: auto;
            font-weight: 400;
            font-size: 1.1em;
            opacity: 1;
            margin:0;
            color: $black;
            float:left;
          }
          &.active,&.nav-path-selected{
            background-color: $brand-secondary;
            width:100%;
            span{
              color: $white;
              margin-left:0;
            }
          }
        }
        .sub-nav{
          position: relative;
          width: 100%;
          opacity:1;
          left:auto;
          float:left;
          display:none;
          list-style: none;
          padding-left: 0;
          background-color: #eaeaea;
          &.open{
            display: block;
          }
          li{
            &:hover{
              >a{
                text-decoration:none;
                width:100%;
              }
            }
            a{
              font-size: 0.8em;
              padding: 9px 10px 9px 20px;
              background-color: #eaeaea;
              &.active,&.nav-path-selected{
                background-color: $brand-secondary;
                border-top: 1px solid #eaeaea;
                span{
                  color: $white;
                }
              }
              span{
                display:inline-block;
                margin-left:0px;
                margin-top:0px;
              }
            }
            .sub-nav{
            }
          }
        }
      }
    }
  }
}
#mobileMainNav{
  .header-logo{
    width: 100%;
    margin: 0;
    img{
      width: 100%!important;
      height: auto!important;;
    }
  }
}
@media all and (min-width: $screen-sm) and (max-width: $screen-sm-max) {
  .top-container .main-nav {
    display: none!important;
  }
  #menuToggle{
    display: inline-block;
  }
}
@media all and (min-width: $screen-xs) and (max-width: $screen-sm) {
  .ccm-block-page-list-page-entry-grid-thumbnail-title{
    font-size: 16px!important;
  }
}
@media all and (max-width: $screen-xs){
  .rslides_nav{
    display: none;
  }
  .ccm-image-slider-text{
    display: none;
  }
  .ccm-block-page-list-page-entry-grid-item{
    width: 100%!important;
  }
  .ccm-block-page-list-page-entry-text{
    padding-left: 2px!important;
    width: 100%;
  }
  .ccm-block-page-list-page-entry-thumbnail{
    width: 100%!important;
    display: table-caption!important;
    img{
      width: 100%!important;
    }
  }
}
@media all and (max-width: $screen-xs-max) {
  .top-container .main-nav {
    display: none!important;
  }
  .arrow{
    display: none;
  }
  .absolute-top{
    height: 80px!important;
  }
  .top-language{
    position: relative;
    float: left;
    height: 40px;
    width: 100%;
    padding: 0;
    display: block;
    margin-top: 5px;
    border-top: 1px solid lightgrey;
    .ccm-block-switch-language{
      margin-top: 0;
      width: 100%;
      select{
        width: 130px;
        float: right;
      }
    }
  }
  .footer-info-bar{
    .ccm-block-switch-language{
      display: none;
    }
  }
}

@media all and (min-width: $screen-sm-max) {
  #mobileMainNav {
    display: none !important;
  }
}