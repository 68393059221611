.ccm-block-social-links{
  ul{
    margin-top: 12px;
    margin-bottom:0;
    li{
      height:25px;
      width:25px;
      background-color: $brand-primary;
      padding:0;
      margin-right:10px;
      text-align: center;
      border-radius: 50%;
      @include transition(all .3s ease-in-out .2s);
      &:hover{
        background-color: $brand-secondary;
      }
      a{
        display: block;
        i{
          font-size:12px;
          color:white;
          line-height:25px;
        }
      }
    }
  }
}