.ccm-block-type-form,.ccm-block-express-form{
  .form-group{
    input, textarea, select{
      border-radius: 0;
      border-color: #ccc;
      @include transition(all .2s ease-in-out .1s);
      &:focus{
        box-shadow: none;
        -webkit-box-shadow: none;
        border-color: $brand-primary;
      }
    }
    input{
      text-indent: 5px;
    }
    label{
      text-transform: uppercase;
      font-weight: 400;
      margin-bottom: 0;
    }
  }
  button[type=submit]{
    border-radius: 0;
    border: 1px solid $brand-primary;
    background-color: $brand-primary;
    @include transition(all .3s ease-in-out .2s);
    margin-bottom: 10px;
    &:hover{
      background-color: transparent!important;
      border-color: $brand-primary!important;
      color: $brand-primary;
    }
  }
}