footer{
  padding: 20px 0;
  background-color: $light-gray;
  font-size: 0.9em;
  p,h1,h2,h3,h4,h5,h6,span,a{
    color: $dark-gray;
  }
  h4,h5,h6{
    text-transform: uppercase;
  }
  @media all and (max-width: $screen-xs-max) {
    .footer-logo {
      img{
        width:300px;
        margin:0 auto;
      }
    }
  }
}
.footer-info-bar{
  height:60px;
  padding:0;
  background-color: $brand-secondary;
  font-weight: 200!important;
  a,p,h1,h2,h3,h4,span{color:white;}
  .footer-name{
    font-size:30px;
    line-height:60px;
    text-transform: uppercase;
    float: right;
    span{
      line-height: 65px;
      float: right;
    }
  }
  .ccm-block-switch-language{
    right: 0;
    margin-top: 15px;
    color: $white;
    select{
      border-radius: 0;
      border-color: $white;
      height: 30px;
      color: $white;
      option{
        color: $black;
      }
    }
  }
}
footer.branding{
  padding:10px 0;
  background-color:$black;
  span{
    float: right;
    display: block;
    font-size: 0.7em;
    color:$kiwi-green;
    a{
      color:$white;
      &:hover{
        text-decoration: none;
      }
    }
  }
}
.footer-logo {
  margin-top: 40px;
}