@mixin fa($icon) {
  @extend .fa-#{$icon}
}

@mixin keyframes($name){
  @-webkit-keyframes #{$name}{
    @content;
  }
  @-moz-keyframes #{$name}{
    @content;
  }
  @-o-keyframes #{$name}{
    @content;
  }
  @keyframes #{$name}{
    @content;
  }
}

@mixin make-heading($family, $size, $weight, $color, $transform: none) {
  font-family: $family;
  font-size: $size;
  font-weight: $weight;
  color: $color;
  line-height: $line-height-base;
  text-transform: $transform;
}
@mixin transition($property, $time, $transition, $timeout){
  -webkit-transition: $property $time $transition $timeout;
  -moz-transition: $property $time $transition $timeout;
  -o-transition: $property $time $transition $timeout;
  transition: $property $time $transition $timeout;
}
@mixin boxshadow($horizontal, $vertical, $blur, $spread, $color) {
  -moz-box-shadow: $horizontal $vertical $blur $spread $color;
  -webkit-box-shadow: $horizontal $vertical $blur $spread $color;
  box-shadow: $horizontal $vertical $blur $spread $color;
}
