.banner-icon{
  text-decoration: none!important;
  img{
    width: 40%;
    margin: auto;
  }
  h3{
    text-align: center;
    color: #d0d0d0;
    font-weight: 200;
  }
  &:hover{
    h3{
      color: $brand-primary;
    }
  }
}
.banner{
  text-decoration: none!important;
  &.alternative{
    margin-bottom: 20px;
    img{
      width: 100% !important;

    }
    h3{
      margin-top: 10px;
      position: relative;
      padding: 10px;
      top: -10px;
      color: white;
      background-color: $brand-primary;
    }
    &:hover{
      h3{
        color: $brand-primary;
        background-color: white;
      }
    }
  }
   h3{
     margin-top: 10px;
     color: $brand-primary;
     font-weight: 200;
     position: absolute;
     bottom: 0;
     padding: 10px;
     background-color: white;
     margin-bottom: 20px;
   }
   &:hover{
     h3{
       background-color: $brand-primary;
       color: white;
     }
   }
}
