div.ccm-block-testimonial {
  div.ccm-block-testimonial-image {
    margin-bottom: 10px;
  }

  div.ccm-block-testimonial-text {
    text-align: center;
    .ccm-block-testimonial-name{
      font-weight: 600;
    }
  }

  img {
    width:130px;
    @include img-responsive;
    border-radius: 50%;
    margin: 0 auto;
    border: 5px solid #eee;
  }

  div.ccm-block-testimonial-position {
    color: $black;
    a {
      color: $brand-primary;
      text-decoration: underline;
    }
  }

  div.ccm-block-testimonial-paragraph {
  }
}

div.ccm-block-testimonial-wrapper   {
  margin-top: 20px;
  margin-bottom: 40px;
  &:last-child{
    margin-bottom: 0;
  }
}

@media all and (max-width: $screen-xs-max) {
  .ccm-block-testimonial-image {
    img {
      margin: 10px auto;
    }
  }

}